import { Helmet } from "react-helmet";

function TermsConditions() {
  return (
    <>
      <Helmet>
        <title>Terms &amp; Conditions : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="py-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="text-center">
                <h1 className="display-3 fw-bold">Terms &amp; Conditions</h1>
                <p className="lead px-8">
                  Read our terms below to learn more about your rights and
                  responsibilities as a Sarkari Result, Sarkari Naukri user.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="py-3 mb-5 border-bottom">
                <h3>
                  Effective date:
                  <span className="text-primary"> 23rd of March, 2024</span>
                </h3>
              </div>

              <h1 className="fw-bold mb-3">Introduction</h1>

              <p className="fs-4 mb-4">
                Welcome to Sarkari Result, Sarkari Naukri. By accessing and
                using our website, you agree to comply with and be bound by
                these Terms &amp; Conditions. Please read them carefully. If you
                do not agree to these terms, please do not use our website.
                These Terms &amp; Conditions apply to all users of the site,
                including but not limited to browsers, vendors, customers, and
                others who interact with our services.
              </p>

              <p className="mb-1 fs-4">
                <span className="text-dark fw-semibold">Email: </span>
                support@examsvacancy.com
              </p>

              <div className="mt-5">
                <div>
                  <h3 className="fw-bold">1. Acceptance of Terms</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">1.1 Agreement to Terms</h3>
                  <p className="fs-4">
                    By accessing or using our website, you agree to be bound by
                    these Terms &amp; Conditions and our Privacy Policy. If you
                    disagree with any part of these terms, you must not use our
                    website. We reserve the right to modify or revise these
                    Terms &amp; Conditions at any time, and your continued use
                    of the website following the posting of any changes
                    constitutes acceptance of those changes.
                  </p>
                </div>
              </div>

              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">2. Use of the Website</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">2.1 Website Access</h3>
                  <p className="fs-4">
                    We grant you a limited, non-exclusive, non-transferable
                    license to access and use the website for personal and
                    non-commercial purposes only. You may not use our website
                    for any unlawful purpose or in any manner that could damage,
                    disable, overburden, or impair the website.
                  </p>
                  <h3 className="fw-bold">2.2 Prohibited Activities</h3>
                  <p className="fs-4">
                    You agree not to engage in any of the following prohibited
                    activities: (a) copying, modifying, or distributing any part
                    of the website; (b) transmitting any harmful code or
                    malware; (c) using any automated means to access the
                    website; (d) violating any applicable laws or regulations.
                  </p>
                </div>
              </div>

              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">3. Job Listings and Information</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">3.1 Accuracy of Information</h3>
                  <p className="fs-4">
                    We strive to provide accurate and up-to-date information
                    regarding job vacancies, exam notifications, and result
                    updates. However, we do not guarantee the accuracy,
                    completeness, or reliability of any information provided on
                    the website. We are not responsible for any errors or
                    omissions in the information or for any actions taken based
                    on such information.
                  </p>
                  <h3 className="fw-bold">3.2 External Links</h3>
                  <p className="fs-4">
                    Our website may contain links to third-party websites or
                    resources. We do not endorse or control these external sites
                    and are not responsible for their content or practices. You
                    access these external sites at your own risk and should
                    review their terms and policies before interacting with
                    them.
                  </p>
                </div>
              </div>

              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">4. User Responsibilities</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">4.1 Account Security</h3>
                  <p className="fs-4">
                    If you create an account on our website, you are responsible
                    for maintaining the confidentiality of your account
                    credentials and for all activities that occur under your
                    account. You agree to notify us immediately of any
                    unauthorized use of your account or any other breach of
                    security.
                  </p>
                  <h3 className="fw-bold">4.2 User Conduct</h3>
                  <p className="fs-4">
                    You agree to use the website in a manner that is lawful and
                    respectful of others. You will not post or transmit any
                    content that is defamatory, obscene, offensive, or otherwise
                    objectionable. We reserve the right to remove any content
                    that violates these Terms &amp; Conditions or is otherwise
                    harmful to the website or its users.
                  </p>
                </div>
              </div>

              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">5. Intellectual Property</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">5.1 Ownership</h3>
                  <p className="fs-4">
                    All content, trademarks, and other intellectual property on
                    our website are the property of Sarkari Result, Sarkari
                    Naukri or its licensors. You may not use, reproduce,
                    distribute, or create derivative works based on any content
                    without our prior written consent.
                  </p>
                  <h3 className="fw-bold">5.2 Feedback</h3>
                  <p className="fs-4">
                    If you provide us with feedback or suggestions regarding our
                    website, you grant us a perpetual, royalty-free, and
                    worldwide license to use, modify, and incorporate such
                    feedback into our services without any obligation to you.
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <h2 className="fw-bold">6. Limitation of Liability</h2>
                <p className="fs-4">
                  To the maximum extent permitted by law, Sarkari Result,
                  Sarkari Naukri are not liable for any indirect, incidental,
                  special, or consequential damages arising out of or in
                  connection with your use of the website. Our total liability
                  for any claim arising out of these Terms &amp; Conditions
                  shall not exceed the amount paid by you, if any, for accessing
                  the website.
                </p>
              </div>

              <div className="mt-5">
                <h2 className="fw-bold">7. Indemnification</h2>
                <p className="fs-4">
                  You agree to indemnify, defend, and hold harmless Sarkari
                  Result and Sarkari Naukri, its affiliates, officers,
                  directors, employees, and agents from any claims, liabilities,
                  damages, losses, or expenses arising out of or related to your
                  use of the website or your breach of these Terms &amp;
                  Conditions.
                </p>
              </div>

              <div className="mt-5">
                <h2 className="fw-bold">8. Termination</h2>
                <p className="fs-4">
                  We reserve the right to terminate or suspend your access to
                  the website at our sole discretion, without notice, for any
                  reason, including but not limited to violation of these Terms
                  &amp; Conditions. Upon termination, your right to use the
                  website will immediately cease.
                </p>
              </div>

              <div className="mt-5">
                <h2 className="fw-bold">9. Governing Law</h2>
                <p className="fs-4">
                  These Terms &amp; Conditions shall be governed by and
                  construed in accordance with the laws of India. Any disputes
                  arising out of or in connection with these terms shall be
                  subject to the exclusive jurisdiction of the courts in New
                  Delhi, India.
                </p>
              </div>

              <div className="mt-5">
                <h2 className="fw-bold">10. Changes to Terms</h2>
                <p className="fs-4">
                  We may update or modify these Terms &amp; Conditions from time
                  to time. Any changes will be posted on this page, and your
                  continued use of the website constitutes acceptance of the
                  revised terms. Registered users will be notified of
                  significant changes via email.
                </p>
                <p className="fs-4">
                  If you have any questions about these Terms &amp; Conditions,
                  please contact us at{" "}
                  <a href="mailto:support@examsvacancy.com">
                    support@examsvacancy.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsConditions;
