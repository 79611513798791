import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Card from "./Card.js";
import { server } from "../server.js";
import { Helmet } from "react-helmet";
import AdSense from "./Adsense.js";

function Category() {
  const { state } = useLocation();
  const categoryName = state?.categoryName;
  const categoryId = state?.categoryId;

  const API = `${server}/data.php`;
  const [fetchData, setFetchData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API}?ev_data_id=${categoryId}`);
        const data = response.data;
        setFetchData(data);
        setFilteredData(data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchDataFromAPI();
  }, [categoryId]);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredData(fetchData);
    } else {
      const filtered = fetchData.filter(
        (post) =>
          post.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          post.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchQuery, fetchData]);

  const totalData = filteredData.length;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [categoryId]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>{categoryName} : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="pt-xl-5 pt-6 glow-bg bg-white">
        <div className="container">
          <div className="row my-4">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <h1 className="text-dark fs-5 m-0 fw-normal">
                      SARKARI RESULT, SARKARI NAUKRI - GOVERNMENT JOBS
                    </h1>
                  </div>
                  <h2 className="display-4 mb-0 lh-1">
                    Explore the Newest Job Openings
                    <br />
                    <span className="text-primary">{categoryName}</span>
                  </h2>
                  <form
                    className="form-inline px-xl-8 mx-xl-8 mt-3"
                    onSubmit={handleSubmit}
                  >
                    <div className="d-flex align-items-center">
                      <span className="position-absolute ps-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                      </span>
                      <input
                        type="search"
                        className="form-control ps-6 form-control-lg"
                        placeholder={`Filter ${categoryName}`}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container text-align-last mb-5 pb-3">
          <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
            <AdSense adClient="ca-pub-5680003571314768" adSlot="4137706151" />
          </div>
        </div>
      </section>

      <section className="pt-2 pb-lg-8 pb-8 bg-white">
        <div className="container mb-4">
          <div className="row">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div className="row align-items-center mb-5">
                <div className="col">
                  <p className="mb-0">
                    Showing {totalData} results of {categoryName}
                  </p>
                </div>
              </div>
              <div className="row g-3">
                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error loading data: {error.message}</p>
                ) : filteredData.length === 0 ? (
                  <p>No results found.</p>
                ) : (
                  filteredData.slice(0, 100).map((post) => {
                    const {
                      ev_id,
                      name,
                      tag,
                      title,
                      postupdatedate,
                      vacancy_details,
                    } = post;
                    return (
                      <Card
                        key={ev_id}
                        ev_id={ev_id}
                        name={name}
                        tag={tag}
                        vacancy_details={vacancy_details}
                        title={title}
                        postupdatedate={postupdatedate}
                      />
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container text-align-last mb-5 pb-3">
        <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
          <AdSense adClient="ca-pub-5680003571314768" adSlot="6624138816" />
        </div>
      </div>
    </>
  );
}

export default Category;
