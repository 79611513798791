import { Link } from "react-router-dom";
import { Departments, States } from "./Home";
import { fetchVacancies, formatTitle } from "../utils";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AdSense from "./Adsense";

function Vacancies() {
  const [departmentVacancies, setDepartmentVacancies] = useState({});
  const [stateVacancies, setStateVacancies] = useState({});
  const [activeTab, setActiveTab] = useState("Departments");

  useEffect(() => {
    const fetchAllVacancies = async () => {
      const counts = {};
      for (const post of States) {
        const count = await fetchVacancies(post.categoryId);
        counts[post.categoryId] = count;
      }
      setStateVacancies(counts);
    };

    fetchAllVacancies();
  }, []);

  useEffect(() => {
    const fetchAllVacancies = async () => {
      const counts = {};
      for (const post of Departments) {
        const count = await fetchVacancies(post.categoryId);
        counts[post.categoryId] = count;
      }
      setDepartmentVacancies(counts);
    };

    fetchAllVacancies();
  }, []);

  return (
    <>
      <Helmet>
        <title>{activeTab} : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="pt-xl-5 pt-6 glow-bg bg-white">
        <div className="container pb-5">
          <div className="row my-4">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <h1 className="text-dark fs-5 m-0 fw-normal">
                      SARKARI RESULT, SARKARI NAUKRI - GOVERNMENT JOBS
                    </h1>
                  </div>
                  <h2 className="display-4 mb-0 lh-1">
                    Explore the Newest Job Openings
                    <br />
                    <span className="text-primary">Vacancies</span>
                  </h2>
                </div>
              </div>

              <div className="mt-5 mx-lg-5 px-lg-5">
                <div className="tabs-border p-1 rounded-pill">
                  <div className="row g-1">
                    <div className="col-6">
                      <div
                        className={`btn fw-medium border-0 rounded-pill w-100 ${
                          activeTab === "Departments"
                            ? "btn-primary"
                            : "text-dark"
                        }`}
                        onClick={() => setActiveTab("Departments")}
                      >
                        Departments
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className={`btn fw-medium border-0 rounded-pill px-4 w-100 ${
                          activeTab === "States" ? "btn-primary" : "text-dark"
                        }`}
                        onClick={() => setActiveTab("States")}
                      >
                        States
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container text-align-last mb-5 pb-3">
        <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
          <AdSense adClient="ca-pub-5680003571314768" adSlot="4137706151" />
        </div>
      </div>

      {activeTab === "Departments" && (
        <section className="pb-lg-6 pb-7 pt-2">
          <div className="container mb-5 pb-4">
            <div className="row">
              <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
                <div className="row g-3">
                  {Departments.map((post) => {
                    const { ev_id, name, categoryId, image } = post;
                    const vacancyCount =
                      departmentVacancies[categoryId] ?? "...";
                    return (
                      <div className="col-lg-3 col-md-4 col-6" key={ev_id}>
                        <Link
                          to={`/category/${formatTitle(name)}`}
                          state={{
                            categoryName: name,
                            categoryId: categoryId,
                          }}
                          className="card border-top border-4 card-hover-with-icon"
                        >
                          <div className="card-body">
                            <div className="icon-shape icon-lg rounded-circle bg-light mb-3 card-icon">
                              <img src={image} width="30" />
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <h4 className="mb-0">{name}</h4>
                                <p className="mb-0">
                                  {vacancyCount}+ Vacancies
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {activeTab === "States" && (
        <section className="pb-lg-6 pb-7 pt-2">
          <div className="container mb-5 pb-4">
            <div className="row">
              <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
                <div className="row g-3">
                  {States.map((post) => {
                    const { ev_id, name, categoryId, image } = post;
                    const vacancyCount = stateVacancies[categoryId] ?? "...";
                    return (
                      <div className="col-lg-3 col-md-4 col-6" key={ev_id}>
                        <Link
                          to={`/category/${formatTitle(name)}`}
                          state={{
                            categoryName: name,
                            categoryId: categoryId,
                          }}
                          className="card border-top border-4 card-hover-with-icon"
                        >
                          <div className="card-body">
                            <div className="icon-shape icon-lg rounded-circle bg-light mb-3 card-icon">
                              <img src={image} width="35" />
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <h4 className="mb-0">{name}</h4>
                                <p className="mb-0">
                                  {vacancyCount}+ Vacancies
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <div className="container text-align-last mb-5 pb-3">
        <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
          <AdSense adClient="ca-pub-5680003571314768" adSlot="4137706151" />
        </div>
      </div>
    </>
  );
}

export default Vacancies;
