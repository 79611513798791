import { Helmet } from "react-helmet";

function Cookie() {
  return (
    <>
      <Helmet>
        <title>Cookie Notice : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="py-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="text-center">
                <h1 className="display-3 fw-bold">Cookie Notice</h1>
                <p className="lead px-8">
                  Learn about our use of cookies and how you can manage them.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="py-3 mb-5 border-bottom">
                <h3>
                  Effective date:
                  <span className="text-primary"> 23rd of March, 2024</span>
                </h3>
              </div>
              <h1 className="fw-bold mb-3">Introduction</h1>
              <p className="fs-4 mb-4">
                At Sarkari Result, Sarkari Naukri, we use cookies to enhance
                your browsing experience, analyze site traffic, and personalize
                content. This Cookie Notice explains what cookies are, how we
                use them, and how you can manage them.
              </p>
              <div className="mt-5">
                <div>
                  <h3 className="fw-bold">1. What Are Cookies?</h3>
                </div>
                <div className="ms-3">
                  <p className="fs-4">
                    Cookies are small text files that are placed on your device
                    (computer, smartphone, or tablet) when you visit a website.
                    They help the website remember your preferences, track your
                    browsing behavior, and provide a more personalized user
                    experience.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">2. How We Use Cookies</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">2.1 Essential Cookies</h3>
                  <p className="fs-4">
                    These cookies are necessary for the functioning of our
                    website. They enable you to navigate the site and use its
                    features, such as accessing secure areas or completing
                    forms. Without these cookies, certain services may not be
                    available.
                  </p>
                  <h3 className="fw-bold">2.2 Performance Cookies</h3>
                  <p className="fs-4">
                    Performance cookies collect information about how you use
                    our website, such as which pages you visit most often and
                    whether you encounter any errors. This helps us improve the
                    performance and functionality of our site.
                  </p>
                  <h3 className="fw-bold">2.3 Functional Cookies</h3>
                  <p className="fs-4">
                    Functional cookies allow us to remember your preferences and
                    choices, such as your username, language, or region. This
                    enables us to provide a more personalized experience
                    tailored to your needs.
                  </p>
                  <h3 className="fw-bold">2.4 Advertising Cookies</h3>
                  <p className="fs-4">
                    These cookies are used to deliver advertisements that are
                    relevant to you and your interests. They may also be used to
                    limit the number of times you see an ad and measure the
                    effectiveness of advertising campaigns.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">3. Third-Party Cookies</h3>
                </div>
                <div className="ms-3">
                  <p className="fs-4">
                    We may also use third-party cookies from partners or service
                    providers. These cookies are managed by third parties and
                    are subject to their own privacy policies. We do not control
                    these cookies but we ensure that our partners comply with
                    our standards.
                  </p>
                  <h3 className="fw-bold">3.1 Analytics</h3>
                  <p className="fs-4">
                    We use third-party analytics services to analyze website
                    traffic and user behavior. These services use cookies to
                    collect information about your visits to our site and other
                    websites. This data helps us improve our website and
                    services.
                  </p>
                  <h3 className="fw-bold">3.2 Advertising</h3>
                  <p className="fs-4">
                    We may use third-party advertising networks to serve ads on
                    our site. These networks use cookies to track your browsing
                    behavior and display ads that match your interests.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">4. Managing Cookies</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">4.1 Your Choices</h3>
                  <p className="fs-4">
                    You can manage your cookie preferences through your browser
                    settings. Most browsers allow you to block or delete
                    cookies, or notify you when cookies are being used. Please
                    refer to your browser’s help section for instructions on how
                    to manage cookies.
                  </p>
                  <h3 className="fw-bold">4.2 Impact on Your Experience</h3>
                  <p className="fs-4">
                    If you choose to disable cookies, some features of our
                    website may not function properly or may be less effective.
                    However, you can still access the majority of our website’s
                    content and services.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">5. Changes to This Cookie Notice</h3>
                </div>
                <div className="ms-3">
                  <p className="fs-4">
                    We may update this Cookie Notice from time to time to
                    reflect changes in our practices or legal requirements. Any
                    changes will be posted on this page, and your continued use
                    of the website after such changes constitutes acceptance of
                    the revised Cookie Notice.
                  </p>
                  <p className="fs-4">
                    We encourage you to review this notice periodically to stay
                    informed about how we use cookies and to ensure you are
                    comfortable with our practices.
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <h2 className="fw-bold">6. Contact Us</h2>
                <p className="fs-4">
                  If you have any questions or concerns about this Cookie Notice
                  or our use of cookies, please contact us at:{" "}
                  <a href="mailto:cookie@examsvacancy.com">
                    cookie@examsvacancy.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cookie;
