import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  calculatePost,
  fetchSavedJobs,
  formatLinks,
  formatList,
  formatTitle,
  toggleSaveJob,
} from "../utils.js";
import { Link, useLocation } from "react-router-dom";
import { server } from "../server.js";
import { Helmet } from "react-helmet";
import AdSense from "./Adsense.js";

function Post() {
  const { state } = useLocation();
  const postId = state?.postId;

  const API = `${server}/details.php`;
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    if (postId) {
      axios
        .get(`${API}?ev_details_id=${postId}`)
        .then((res) => setPostData(res.data))
        .catch((error) => console.log("Facing Issue"));
    }
  }, [postId]);

  const LatestPostAPI = `${server}/notifications.php`;
  const [latestPosts, setLatestPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLatestPosts = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${LatestPostAPI}?limit=4`);
        setLatestPosts(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchLatestPosts();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [postId]);

  const [savedJobs, setSavedJobs] = useState([]);

  useEffect(() => {
    fetchSavedJobs(setSavedJobs);
  }, []);

  const handleToggleSaveJob = (ev_id) => {
    toggleSaveJob(ev_id, setSavedJobs);
  };

  const isJobSaved = (ev_id) => {
    return savedJobs.includes(ev_id);
  };

  return (
    <>
      <section className="pt-lg-8 pb-3 py-7 bg-white">
        <div className="container mt-lg-3">
          <div className="row">
            {postData.map((post) => {
              const {
                ev_id,
                name,
                tag,
                nameofpost,
                postupdatedate,
                shortdescription,
                title,
                subtitle,
                important_dates,
                application_fee,
                vacancy_details,
                external_links,
              } = post;
              return (
                <>
                  <Helmet>
                    <title>{name} : Sarkari Result, Sarkari Naukri</title>
                  </Helmet>

                  <div className="container text-align-last mb-5 pb-3">
                    <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
                      <AdSense
                        adClient="ca-pub-5680003571314768"
                        adSlot="4137706151"
                      />
                    </div>
                  </div>

                  <div className="offset-xl-2 col-xl-8 col-md-12" key={ev_id}>
                    <div className="d-xl-flex light-bg-primary p-5 rounded-4">
                      <div className="w-100 mt-3 mt-xl-0">
                        <div className="d-flex justify-content-between mb-5">
                          <div>
                            <h1 className="mb-1 h2">{name}</h1>
                            <div>
                              <span>
                                from
                                <span className="text-dark ms-1">{tag}</span>
                                <i className="bi bi-graph-up ms-3 me-2"></i>
                              </span>
                              <span className="ms-0">
                                {calculatePost(vacancy_details)}
                              </span>
                            </div>
                          </div>
                          <div>
                            <i
                              onClick={() => handleToggleSaveJob(ev_id)}
                              className={`bi ${
                                isJobSaved(ev_id)
                                  ? "bi-bookmark-fill"
                                  : "bi-bookmark"
                              }`}
                            ></i>
                          </div>
                        </div>
                        <div>
                          <div className="d-md-flex justify-content-between">
                            <div>
                              <div className="mb-2 mb-md-0">
                                <span className="me-2">
                                  <i className="fe fe-briefcase" />
                                  <span>{title}</span>
                                </span>
                              </div>
                            </div>
                            <div>
                              <span>
                                {moment(postupdatedate).format("DD/MM/YYYY")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="my-5" />
                    <div>
                      <p>
                        <span>{subtitle}</span>
                      </p>
                    </div>
                    <div className="mt-6">
                      <h2 className="mb-3 fs-3">Post Name</h2>
                      <p>{nameofpost}</p>
                    </div>

                    <div className="mt-6">
                      <h2 className="mb-3 fs-3">Short Description</h2>
                      <p>{shortdescription}</p>
                    </div>

                    <div className="mt-6">
                      <h2 className="mb-3 fs-3">Important Dates</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formatList(important_dates),
                        }}
                      />
                    </div>

                    <div className="mt-6">
                      <h2 className="mb-3 fs-3">Application Fee</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formatList(application_fee),
                        }}
                      />
                    </div>

                    <div className="mt-6">
                      <h2 className="mb-3 fs-3">Vacancy Details</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formatList(vacancy_details),
                        }}
                      />
                    </div>

                    <div className="mt-6">
                      <h2 className="mb-3 fs-3">Some Important Links</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: formatLinks(external_links),
                        }}
                      />
                    </div>

                    <div className="mt-5 pt-3 d-grid">
                      <a
                        href={`https://www.examsvacancy.com/pdf.php?ev_details_id=${ev_id}`}
                        className="btn btn-primary"
                        target="_blank"
                      >
                        Download Eligibility Details (PDF)
                      </a>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>

      <div className="container text-align-last my-5">
        <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
          <AdSense adClient="ca-pub-5680003571314768" adSlot="4137706151" />
        </div>
      </div>

      <section className="pb-8 bg-white">
        <div className="container">
          <div className="row">
            <div className="offset-xl-2 col-xl-8 col-md-12 col-12">
              <div className="mt-8">
                <div className="mb-6">
                  <span className="text-uppercase text-primary fw-semibold ls-md">
                    Latest Updates
                  </span>
                </div>

                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error fetching posts</p>
                ) : (
                  latestPosts.map((post) => {
                    const {
                      ev_id,
                      name,
                      tag,
                      title,
                      postupdatedate,
                      vacancy_details,
                    } = post;
                    return (
                      <Link
                        to={`/post/${formatTitle(name)}`}
                        state={{ postId: ev_id }}
                        className="card card-bordered rounded-4 mb-3 card-hover cursor-pointer"
                        key={ev_id}
                      >
                        <div className="card-body">
                          <div className="d-md-flex">
                            <div className="w-100 px-lg-3 px-1 mt-3 mt-xl-1">
                              <div className="d-flex justify-content-between mb-5">
                                <div>
                                  <h3 className="mb-1 fs-4">
                                    <span className="text-inherit">{name}</span>
                                    <span className="badge bg-danger-soft ms-2">
                                      Latest
                                    </span>
                                  </h3>

                                  <div>
                                    <span>
                                      from
                                      <span className="text-dark ms-1">
                                        {tag}
                                      </span>
                                      <i className="bi bi-graph-up ms-3 me-2"></i>
                                    </span>
                                    <span className="ms-0">
                                      {calculatePost(vacancy_details)}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <i
                                    onClick={() => handleToggleSaveJob(ev_id)}
                                    className={`bi ${
                                      isJobSaved(ev_id)
                                        ? "bi-bookmark-fill"
                                        : "bi-bookmark"
                                    }`}
                                  ></i>
                                </div>
                              </div>

                              <div className="d-md-flex justify-content-between">
                                <div className="mb-2 mb-md-0">
                                  <span className="me-2">
                                    <span>{title}</span>
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    {moment(postupdatedate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container text-align-last mb-5 pb-5">
        <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
          <AdSense adClient="ca-pub-5680003571314768" adSlot="4137706151" />
        </div>
      </div>
    </>
  );
}

export default Post;
