import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  calculatePost,
  fetchSavedJobs,
  formatTitle,
  toggleSaveJob,
} from "../utils";

const Card = ({ ev_id, name, tag, vacancy_details, title, postupdatedate }) => {
  const [savedJobs, setSavedJobs] = useState([]);

  useEffect(() => {
    fetchSavedJobs(setSavedJobs);
  }, []);

  const handleToggleSaveJob = (ev_id) => {
    toggleSaveJob(ev_id, setSavedJobs);
  };

  const isJobSaved = (ev_id) => {
    return savedJobs.includes(ev_id);
  };

  return (
    <div className="col-lg-6 col-12" key={ev_id}>
      <Link
        to={`/post/${formatTitle(name)}`}
        state={{ postId: ev_id }}
        className="card card-bordered card-hover rounded-4 cursor-pointer h-100 p-2"
      >
        <div className="card-body">
          <div className="w-100">
            <div className="d-flex justify-content-between mb-4">
              <div>
                <h3 className="mb-1 fs-4">
                  <span className="text-inherit me-2">{name}</span>
                  <span className="badge bg-danger-soft me-4">Latest</span>
                </h3>
                <div>
                  <span>
                    from
                    <span className="text-dark ms-1">{tag}</span>
                    <i className="bi bi-graph-up ms-3 me-2"></i>
                  </span>
                  <span className="ms-0">{calculatePost(vacancy_details)}</span>
                </div>
              </div>
              <div>
                <i
                  onClick={() => handleToggleSaveJob(ev_id)}
                  className={`bi ${
                    isJobSaved(ev_id) ? "bi-bookmark-fill" : "bi-bookmark"
                  }`}
                ></i>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-4">
              <div className="mb-2 mb-md-0">
                <div className="mt-1">
                  <span>{title}</span>
                </div>
              </div>
            </div>
            <div>
              <i className="bi bi-calendar4-event me-2"></i>
              <span>{moment(postupdatedate).format("DD/MM/YYYY")}</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
