import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="py-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="text-center">
                <h1 className="display-3 fw-bold">Privacy Policy</h1>
                <p className="lead px-8">
                  Learn how we handle your personal information and what
                  measures we take to protect your privacy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="py-3 mb-5 border-bottom">
                <h3>
                  Effective date:
                  <span className="text-primary"> 23rd of March, 2024</span>
                </h3>
              </div>
              <h1 className="fw-bold mb-3">Introduction</h1>
              <p className="fs-4 mb-4">
                Welcome to Sarkari Result, Sarkari Naukri. Your privacy is
                important to us. This Privacy Policy explains how we collect,
                use, disclose, and safeguard your information when you visit our
                website. Please read this policy carefully. By using our
                website, you consent to the practices described herein.
              </p>
              <p className="mb-1 fs-4">
                <span className="text-dark fw-semibold">Email: </span>
                privacy@examsvacancy.com
              </p>

              <div className="mt-5">
                <div>
                  <h3 className="fw-bold">1. Information We Collect</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">1.1 Personal Information</h3>
                  <p className="fs-4">
                    We may collect personal information such as your name, email
                    address, phone number, and any other details you provide
                    when you register for an account, subscribe to our
                    newsletter, or interact with our website in other ways.
                  </p>
                  <h3 className="fw-bold">1.2 Usage Data</h3>
                  <p className="fs-4">
                    We collect information about your interactions with our
                    website, such as your IP address, browser type, pages
                    visited, and the dates and times of your visits. This helps
                    us understand how users engage with our site and improve its
                    functionality.
                  </p>
                  <h3 className="fw-bold">
                    1.3 Cookies and Tracking Technologies
                  </h3>
                  <p className="fs-4">
                    Our website uses cookies and similar tracking technologies
                    to enhance your experience. Cookies are small data files
                    stored on your device that help us remember your preferences
                    and track usage patterns. You can control cookie settings
                    through your browser.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">2. How We Use Your Information</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">2.1 Providing Services</h3>
                  <p className="fs-4">
                    We use your personal information to provide and manage our
                    services, including job alerts, exam notifications, and
                    result updates. This includes sending you relevant
                    information and updates based on your preferences.
                  </p>
                  <h3 className="fw-bold">2.2 Improving User Experience</h3>
                  <p className="fs-4">
                    Usage data helps us understand how our website is used and
                    identify areas for improvement. We may use this data to
                    enhance the functionality and performance of our site.
                  </p>
                  <h3 className="fw-bold">2.3 Marketing and Communication</h3>
                  <p className="fs-4">
                    With your consent, we may use your information to send you
                    promotional materials and updates about our services. You
                    can opt-out of receiving marketing communications at any
                    time by following the unsubscribe instructions provided in
                    those communications.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">3. How We Share Your Information</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">3.1 Third-Party Service Providers</h3>
                  <p className="fs-4">
                    We may share your information with third-party service
                    providers who assist us in operating our website, conducting
                    business, or providing services to you. These providers are
                    obligated to protect your information and use it only for
                    the purposes for which it was disclosed.
                  </p>
                  <h3 className="fw-bold">3.2 Legal Requirements</h3>
                  <p className="fs-4">
                    We may disclose your information if required by law or in
                    response to valid requests from public authorities. This
                    includes compliance with legal processes, enforcing our
                    Terms &amp; Conditions, or protecting our rights, privacy,
                    safety, or property.
                  </p>
                  <h3 className="fw-bold">3.3 Business Transfers</h3>
                  <p className="fs-4">
                    In the event of a merger, acquisition, or sale of assets, we
                    may transfer your information to the acquiring entity. We
                    will notify you of any such change and how it may affect the
                    handling of your information.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">4. Data Security</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">4.1 Security Measures</h3>
                  <p className="fs-4">
                    We implement reasonable security measures to protect your
                    information from unauthorized access, alteration,
                    disclosure, or destruction. However, no data transmission or
                    storage system can be guaranteed to be 100% secure.
                  </p>
                  <h3 className="fw-bold">4.2 Data Retention</h3>
                  <p className="fs-4">
                    We retain your information for as long as necessary to
                    fulfill the purposes for which it was collected, to comply
                    with legal obligations, or to resolve disputes. Once your
                    information is no longer needed, we will securely delete or
                    anonymize it.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">5. Your Rights</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">5.1 Access and Correction</h3>
                  <p className="fs-4">
                    You have the right to access and review the personal
                    information we hold about you. If you believe that any
                    information is inaccurate or incomplete, you may request
                    corrections or updates.
                  </p>
                  <h3 className="fw-bold">5.2 Opt-Out</h3>
                  <p className="fs-4">
                    You can opt-out of receiving promotional communications from
                    us at any time by following the unsubscribe instructions in
                    those messages. However, you may still receive
                    non-promotional communications related to your account or
                    service requests.
                  </p>
                  <h3 className="fw-bold">5.3 Data Deletion</h3>
                  <p className="fs-4">
                    You may request the deletion of your personal information
                    from our records. We will comply with such requests unless
                    we are required to retain the information for legal or
                    operational purposes.
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <h2 className="fw-bold">6. Changes to This Privacy Policy</h2>
                <p className="fs-4">
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or legal requirements. Any changes
                  will be posted on this page, and your continued use of the
                  website after such changes constitutes acceptance of the
                  revised policy.
                </p>
                <p className="fs-4">
                  We encourage you to review this policy periodically to stay
                  informed about how we protect your information.
                </p>
              </div>
              <div className="mt-5">
                <h2 className="fw-bold">7. Contact Us</h2>
                <p className="fs-4">
                  If you have any questions or concerns about this Privacy
                  Policy or our practices, please contact us at:{" "}
                  <a href="mailto:privacy@examsvacancy.com">
                    privacy@examsvacancy.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
