import { Link } from "react-router-dom";

function NotFound() {
  return (
    <>
      <section className="container d-flex flex-column py-8 my-8">
        <div className="row align-items-center justify-content-center g-0 h-lg-100 my-8">
          <div className="col-lg-6 col-md-12 col-12 text-center">
            <div className="d-flex flex-column gap-5">
              <div className="d-flex flex-column gap-3">
                <h1 className="display-1 mb-0">404</h1>
                <p className="mb-0 lead px-4 px-md-0">
                  Oops! Sorry, we couldn’t find the page you were looking for.
                  If you think this is a problem with us, please{" "}
                  <Link to="/contact" className="btn-link">
                    Contact Us
                  </Link>
                </p>
              </div>
              <div>
                <Link to="/" className="btn btn-primary btn-lg me-2 px-5">
                  <i className="bi bi-arrow-left me-3"></i>Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NotFound;
