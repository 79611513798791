import axios from "axios";
import { useState, useEffect } from "react";
import {
  calculatePost,
  fetchSavedJobs,
  formatTitle,
  toggleSaveJob,
} from "../utils.js";
import { Link, useLocation } from "react-router-dom";
import { server } from "../server.js";
import moment from "moment";
import { Helmet } from "react-helmet";
import AdSense from "./Adsense.js";

function Search() {
  const location = useLocation();
  const searchQuery = location.state?.searchQuery || "";

  const [searchTerm, setSearchTerm] = useState(searchQuery);
  const [searchResult, setSearchResult] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchResults = async () => {
      if (searchTerm.trim() === "") {
        setSearchResult([]);
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.get(`${server}/search.php`, {
          params: { search_text: searchTerm },
        });
        setSearchResult(response.data || []);
      } catch (err) {
        console.error("Error fetching search results:", err);
        setError(
          err.response ? err.response.data : "An unexpected error occurred"
        );
        setSearchResult([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (searchTerm) {
      fetchResults();
    }
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (searchTerm.trim() === "") {
      setSearchResult([]);
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(`${server}/search.php`, {
        params: { search_text: searchTerm },
      });
      setSearchResult(response.data || []);
    } catch (err) {
      console.error("Error fetching search results:", err);
      setError(
        err.response ? err.response.data : "An unexpected error occurred"
      );
      setSearchResult([]);
    } finally {
      setIsLoading(false);
    }
  };

  const [savedJobs, setSavedJobs] = useState([]);

  useEffect(() => {
    fetchSavedJobs(setSavedJobs);
  }, []);

  const handleToggleSaveJob = (ev_id) => {
    toggleSaveJob(ev_id, setSavedJobs);
  };

  const isJobSaved = (ev_id) => {
    return savedJobs.includes(ev_id);
  };

  return (
    <>
      <Helmet>
        <title>Search : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="pt-xl-5 pt-6 glow-bg bg-white">
        <div className="container pb-3">
          <div className="row my-4">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <h1 className="text-dark fs-5 m-0 fw-normal">
                      SARKARI RESULT, SARKARI NAUKRI - GOVERNMENT JOBS
                    </h1>
                  </div>
                  <h2 className="display-4 mb-0 lh-1">
                    Explore the Newest Job Openings
                    <br />
                    <span className="text-primary">Search</span>
                  </h2>
                  <form
                    onSubmit={handleSubmit}
                    className="form-inline px-xl-8 mx-xl-8 mt-3"
                  >
                    <div className="d-flex align-items-center">
                      <span className="position-absolute ps-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                      </span>
                      <input
                        type="search"
                        className="form-control ps-6 form-control-lg"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mb-2">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-12 col-12">
              <div className="p-5 rounded-4 ev-card-border bg-white">
                <div className="row align-items-center mx-lg-2">
                  <div className="col-xl-8 col-md-6 col-12">
                    <div className="d-flex align-items-center">
                      <div className="d-lg-block d-none">
                        <div className="p-2 bg-white rounded-4">
                          <img src="/assets/images/mobile.png" width="130" />
                        </div>
                      </div>
                      <div className="ms-lg-4 ms-0 d-flex justify-content-center flex-column">
                        <h2 className="fs-3 mb-2 lh-1">
                          Explore jobs with popular departments
                        </h2>
                        <p className="text-dark-50 fs-5 ev-lh-normal mb-0">
                          Explore a range of exciting job opportunities.
                        </p>
                        <div className="mt-3">
                          <Link
                            to={`/category/${formatTitle("Coast Guard")}`}
                            state={{
                              categoryName: "Coast Guard",
                              categoryId: "s0lzv2h7",
                            }}
                            className="btn btn-tag fs-6 fw-normal text-dark opacity-75 px-3 btn-sm me-2 mb-1"
                          >
                            Coast Guard
                            <i className="bi bi-chevron-right ms-3"></i>
                          </Link>
                          <Link
                            to={`/category/${formatTitle("Police")}`}
                            state={{
                              categoryName: "Police",
                              categoryId: "t1u9o16c",
                            }}
                            className="btn btn-tag fs-6 fw-normal text-dark opacity-75 px-3 btn-sm me-2 mb-1"
                          >
                            Police
                            <i className="bi bi-chevron-right ms-3"></i>
                          </Link>
                          <Link
                            to={`/category/${formatTitle("Banking")}`}
                            state={{
                              categoryName: "Banking",
                              categoryId: "o90g88ug",
                            }}
                            className="btn btn-tag fs-6 fw-normal text-dark opacity-75 px-3 btn-sm me-2 mb-1"
                          >
                            Banking
                            <i className="bi bi-chevron-right ms-3"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-end">
                    <div className="d-flex flex-column text-center">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ev.examsvacancy"
                        target="_blank"
                        className="btn btn-primary fw-normal ms-1 rounded-pill px-5 mt-lg-0 mt-4"
                      >
                        Download App
                      </a>
                      <p className="mt-1 fs-6 mb-0">Explore More</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8 bg-white">
        <div className="container mb-4">
          <div className="row">
            <div className="offset-xl-2 col-xl-8 col-md-12 col-12">
              <div className="mt-8">
                <div className="mb-6">
                  <span className="text-uppercase text-primary fw-semibold ls-md">
                    Showing {searchResult.length} Search results
                  </span>
                </div>

                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error fetching posts</p>
                ) : (
                  searchResult.map((post) => {
                    const {
                      ev_id,
                      name,
                      tag,
                      title,
                      postupdatedate,
                      vacancy_details,
                    } = post;
                    return (
                      <Link
                        to={`/post/${formatTitle(name)}`}
                        state={{ postId: ev_id }}
                        className="card card-bordered mb-3 card-hover cursor-pointer"
                        key={ev_id}
                      >
                        <div className="card-body">
                          <div className="d-md-flex">
                            <div className="w-100 px-lg-3 px-1 mt-3 mt-xl-1">
                              <div className="d-flex justify-content-between mb-5">
                                <div>
                                  <h3 className="mb-1 fs-4">
                                    <span className="text-inherit">{name}</span>
                                    <span className="badge bg-danger-soft ms-2">
                                      Latest
                                    </span>
                                  </h3>

                                  <div>
                                    <span>
                                      from
                                      <span className="text-dark ms-1">
                                        {tag}
                                      </span>
                                      <i className="bi bi-graph-up ms-3 me-2"></i>
                                    </span>
                                    <span className="ms-0">
                                      {calculatePost(vacancy_details)}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <i
                                    onClick={() => handleToggleSaveJob(ev_id)}
                                    className={`bi ${
                                      isJobSaved(ev_id)
                                        ? "bi-bookmark-fill"
                                        : "bi-bookmark"
                                    }`}
                                  ></i>
                                </div>
                              </div>

                              <div className="d-md-flex justify-content-between">
                                <div className="mb-2 mb-md-0">
                                  <span className="me-2">
                                    <span>{title}</span>
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    {moment(postupdatedate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container text-align-last mb-5 pb-3">
        <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
          <AdSense adClient="ca-pub-5680003571314768" adSlot="4137706151" />
        </div>
      </div>
    </>
  );
}

export default Search;
