import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>About Us : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="py-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="text-center">
                <h1 className="display-3 fw-bold">About Us</h1>
                <p className="lead px-8">
                  Discover how Sarkari Result, Sarkari Naukri are dedicated to
                  helping you navigate the world of government jobs with ease
                  and confidence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <h1 className="fw-bold mb-3">Our AIM</h1>
              <p className="fs-4 mb-4">
                At Sarkari Result, Sarkari Naukri, our mission is to simplify
                your search for government jobs and streamline the process of
                obtaining critical information related to exams and results. We
                aim to provide you with the most accurate and up-to-date details
                on job vacancies, exam notifications, and result updates to help
                you achieve your career goals in the public sector.
              </p>
              <h3 className="fw-bold mb-3">
                What Kind of Government Jobs Can I Get?
              </h3>
              <p className="fs-4 mb-4">
                Government jobs cover a wide range of roles across various
                sectors including administration, finance, education, law
                enforcement, health services, and public utilities. Whether
                you're looking for positions in the Indian Administrative
                Service (IAS), Indian Police Service (IPS), teaching jobs,
                clerical posts, or specialized roles in state and central
                government departments, we provide comprehensive information to
                help you find opportunities that match your skills and
                interests.
              </p>
              <h3 className="fw-bold mb-3">
                How Can Sarkari Result, Sarkari Naukri Help Me?
              </h3>
              <p className="fs-4 mb-4">
                Sarkari Result, Sarkari Naukri offer a wealth of resources to
                support your job search. Our website features:
              </p>
              <ul className="fs-4 mb-4">
                <li>
                  <i>Latest Job Listings:</i> Access a comprehensive and updated
                  list of government job vacancies across various departments
                  and levels.
                </li>
                <li>
                  <i>Exam Notifications:</i> Stay informed about upcoming exams
                  with timely notifications and detailed information on
                  eligibility, syllabus, and application procedures.
                </li>
                <li>
                  <i>Result Updates:</i> Receive prompt updates on exam results
                  and merit lists to ensure you never miss out on crucial
                  information.
                </li>
                <li>
                  <i>Application Tips:</i> Benefit from expert tips and advice
                  on how to prepare and apply for government jobs effectively.
                </li>
              </ul>
              <h3 className="fw-bold mb-3">
                How Competitive is the Government Job Market?
              </h3>
              <p className="fs-4 mb-4">
                The government job market in India is highly competitive due to
                the attractive benefits, job security, and prestige associated
                with public sector positions. Thousands of candidates vie for
                limited openings each year, making it essential to stay
                well-informed and prepared. Sarkari Result, Sarkari Naukri help
                you gain a competitive edge by providing timely and accurate
                information, which is crucial for successful application and
                exam preparation.
              </p>
              <h3 className="fw-bold mb-3">
                Why Choose Sarkari Result, Sarkari Naukri?
              </h3>
              <p className="fs-4 mb-4">
                Our commitment to providing accurate, comprehensive, and
                up-to-date information sets us apart. Here's why Sarkari Result
                and Sarkari Naukri are your go-to resources for government job
                information:
              </p>
              <ul className="fs-4 mb-4">
                <li>
                  <i>Reliable Information:</i> We ensure that all the
                  information provided on our platform is accurate and current.
                </li>
                <li>
                  <i>User-Friendly Experience:</i> Our website is designed to
                  make your job search and application process as smooth as
                  possible.
                </li>
                <li>
                  <i>Dedicated Support:</i> Our team is dedicated to assisting
                  you with any queries or concerns you may have regarding
                  government jobs.
                </li>
                <li>
                  <i>Regular Updates:</i> We regularly update our listings and
                  notifications to keep you informed of the latest opportunities
                  and deadlines.
                </li>
              </ul>
              <p className="fs-4 mb-4">
                Join our community and take the first step toward a rewarding
                career in the public sector with Sarkari Result, Sarkari Naukri.
                For any questions or support, feel free to reach out to us at{" "}
                <a href="mailto:support@examsvacancy.com">
                  support@examsvacancy.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
