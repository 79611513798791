import { Helmet } from "react-helmet";

function Disclaimer() {
  return (
    <>
      <Helmet>
        <title>Disclaimer : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="py-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="text-center">
                <h1 className="display-3 fw-bold">Disclaimer</h1>
                <p className="lead px-8">
                  Read our disclaimer to understand the limitations and scope of
                  the information and services provided on our website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="py-3 mb-5 border-bottom">
                <h3>
                  Effective date:
                  <span className="text-primary"> 23rd of March, 2024</span>
                </h3>
              </div>
              <h1 className="fw-bold mb-3">Introduction</h1>
              <p className="fs-4 mb-4">
                Welcome to Sarkari Result, Sarkari Naukri. The information
                provided on this website is for general informational purposes
                only. While we strive to keep the information accurate and
                up-to-date, we make no representations or warranties of any kind
                regarding the completeness, accuracy, reliability, or
                availability of the content. Your use of this website is at your
                own risk.
              </p>
              <div className="mt-5">
                <div>
                  <h3 className="fw-bold">1. No Warranties</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">1.1 Content Accuracy</h3>
                  <p className="fs-4">
                    The information on our website is provided "as is" without
                    any warranties or guarantees. We do not warrant the
                    accuracy, completeness, or reliability of the content and
                    expressly disclaim any liability for errors or omissions.
                  </p>
                  <h3 className="fw-bold">1.2 Availability</h3>
                  <p className="fs-4">
                    We do not guarantee that the website will be available at
                    all times or free of errors or viruses. We are not
                    responsible for any interruptions or damages that may occur
                    as a result of using our website.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">2. External Links</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">2.1 Third-Party Sites</h3>
                  <p className="fs-4">
                    Our website may contain links to third-party websites. These
                    links are provided for your convenience and do not imply
                    endorsement of the content or services provided by these
                    third-party sites. We are not responsible for the content or
                    practices of external sites.
                  </p>
                  <h3 className="fw-bold">2.2 No Liability</h3>
                  <p className="fs-4">
                    We are not liable for any loss or damage that may arise from
                    your use of third-party websites linked from our site. You
                    access these external sites at your own risk and should
                    review their terms and privacy policies.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">3. User Responsibilities</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">3.1 Accuracy of Applications</h3>
                  <p className="fs-4">
                    It is your responsibility to verify the accuracy of any job
                    applications, exam notifications, or result updates provided
                    on our website. We are not responsible for any errors or
                    inaccuracies in the information that may affect your
                    application or results.
                  </p>
                  <h3 className="fw-bold">3.2 Personal Data</h3>
                  <p className="fs-4">
                    Any personal data you provide on our website is done at your
                    own discretion. We are not responsible for any misuse or
                    unauthorized access to your personal data. Please refer to
                    our Privacy Policy for details on how we handle your data.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">4. Limitation of Liability</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">4.1 Exclusion of Damages</h3>
                  <p className="fs-4">
                    To the fullest extent permitted by law, Sarkari Result,
                    Sarkari Naukri shall not be liable for any direct, indirect,
                    incidental, special, or consequential damages arising out of
                    or in connection with your use of the website. This
                    includes, but is not limited to, damages for loss of
                    profits, data, or other intangible losses.
                  </p>
                  <h3 className="fw-bold">4.2 No Liability for Misuse</h3>
                  <p className="fs-4">
                    We are not liable for any damage or loss resulting from your
                    reliance on any information obtained from our website. You
                    acknowledge that it is your responsibility to independently
                    verify the information and its applicability to your
                    circumstances.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <h3 className="fw-bold">5. Changes to the Disclaimer</h3>
                </div>
                <div className="ms-3">
                  <h3 className="fw-bold">5.1 Updates</h3>
                  <p className="fs-4">
                    We may update this Disclaimer from time to time to reflect
                    changes in our practices or legal requirements. Any changes
                    will be posted on this page, and your continued use of the
                    website after such changes constitutes acceptance of the
                    revised Disclaimer.
                  </p>
                  <h3 className="fw-bold">5.2 Review</h3>
                  <p className="fs-4">
                    We encourage you to review this Disclaimer periodically to
                    stay informed about the limitations and scope of the
                    information and services provided on our website.
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <h2 className="fw-bold">6. Contact Us</h2>
                <p className="fs-4">
                  If you have any questions or concerns about this Disclaimer or
                  the content on our website, please contact us at:{" "}
                  <a href="mailto:disclaimer@examsvacancy.com">
                    disclaimer@examsvacancy.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Disclaimer;
