import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="py-7 py-lg-8 bg-white">
        <div className="container mb-lg-8 mt-lg-4">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-lg-5">
              <div className="mb-8">
                <h1 className="display-4 mb-3 fw-bold text-center">
                  Get in Touch
                </h1>
                <p className="mb-0 lead text-center">
                  Feel free to reach out—we'd love to hear from you and answer
                  any questions you may have!
                </p>
              </div>
            </div>
          </div>

          <form className="needs-validation d-flex justify-content-center">
            <div className="col-lg-7 col-md-8 col-12">
              <div>
                <div className="row">
                  <div className="mb-3 col-12 col-md-6">
                    <label className="form-label" htmlFor="fname">
                      First Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="fname"
                      className="form-control"
                      placeholder="First Name"
                      required=""
                    />
                    <div className="invalid-feedback">
                      Please enter first name.
                    </div>
                  </div>
                  <div className="mb-3 col-12 col-md-6">
                    <label className="form-label" htmlFor="lname">
                      Last Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="lname"
                      className="form-control"
                      placeholder="Last Name"
                      required=""
                    />
                    <div className="invalid-feedback">
                      Please enter last name.
                    </div>
                  </div>
                  <div className="mb-3 col-12 col-md-12">
                    <label className="form-label" htmlFor="email">
                      Email
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="Write you Email id"
                      required=""
                    />
                  </div>
                  <div className="mb-3 col-12 col-md-12">
                    <label className="form-label" htmlFor="phone">
                      Phone Number
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        id="phone"
                        className="form-control"
                        placeholder="Phone"
                        required=""
                      />
                      <select
                        className="form-select"
                        id="inputGroupSelect02"
                        style={{ maxWidth: "8rem" }}
                        required=""
                      >
                        <option selected="" value="">
                          Home
                        </option>
                        <option value={1}>Work</option>
                        <option value={2}>Mobile</option>
                      </select>
                      <div className="invalid-feedback">
                        Please enter phone number.
                      </div>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="formNumber"
                        required=""
                      />
                      <label className="form-check-label" htmlFor="formNumber">
                        Send me important updates in this number.
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="row">
                  <div className="col-12 col-md-12 mb-3">
                    <label className="form-label">Your message</label>
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="mb-5 col-12 col-md-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="flexCheckDefault"
                        required=""
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        I accept the{" "}
                        <Link to="/terms-conditions">
                          terms and conditions{" "}
                        </Link>
                        for submitting this form.
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <button type="submit" className="btn btn-primary px-5">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Contact;
