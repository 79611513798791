import React, { useEffect, useRef } from 'react';

const AdSense = ({ adClient, adSlot }) => {
  const adRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (adRef.current) {
        const existingAds = adRef.current.querySelectorAll('.adsbygoogle');
        if (existingAds.length === 0) {
          window.adsbygoogle = window.adsbygoogle || [];
          window.adsbygoogle.push({});
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [adRef]);

  return (
    <ins
      ref={adRef}
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client={adClient}
      data-ad-slot={adSlot}
      data-ad-format="auto"
    />
  );
};

export default AdSense;
